import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import aiModel from "./modules/aiModel";
import apiEndpoints from "./modules/apiEndpoints";
import auth from "./modules/auth";
import companies from "./modules/companies";
import permission from "./modules/permission";
import roi from "./modules/roi";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    dashboardSidebarDrawer: true,
    dashboardSidebarMini: false,
  },

  mutations: {
    setDashboardSidebarDrawer(state, payload) {
      state.dashboardSidebarDrawer = payload;
    },

    setDashboardSidebarMini(state, payload) {
      state.dashboardSidebarMini = payload;
    },
  },

  actions: {},

  modules: {
    aiModel,
    apiEndpoints,
    auth,
    companies,
    permission,
    roi,
  },

  plugins: [createPersistedState()],
});

export default store;
